<template>
  <b-form-group
    :label="label"
    :label-sr-only="noLabel"
    :label-class="{ 'text-required': required }"
  >
    <b-input-group :state="state">
      <b-form-input
        v-model="content"
        :state="state"
        @input="handleInput"
        :type="type"
        :readonly="readonly"
        :formatter="mascaraValor"
        :placeholder="placeholder"
        :lazy="lazy"
        @wheel="$event.target.blur()"
        :min="min"
        :max="max"
        :size="size"
        @keyup.enter.prevent="sendEnterEvent"
      />
      <slot name="append"></slot>
    </b-input-group>
    <b-form-invalid-feedback :state="state" v-if="!hideFeedback">
      {{ feedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "InputPercentage",
  props: {
    stateCustom: { type: Boolean, default: true },
    value: { type: [String, Number], default: "" },
    type: { type: String, default: "text" },
    label: { type: String },
    size: { type: String },
    noLabel: { type: Boolean, default: false },
    required: { type: Boolean },
    requiredMessage: { type: String },
    readonly: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    lengthMessage: { type: String },
    minLength: { type: Number, default: null },
    minLengthMessage: { type: String },
    maxLength: { type: Number, default: null },
    maxLengthMessage: { type: String },
    placeholder: { type: String },
    lazy: { type: Boolean, default: false },
    min: { type: [Number, String], required: false },
    max: { type: [Number, String], required: false },
    hideFeedback: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      hasError: false,
      errorMessage: "",
      inputPasswordIcon: "fas fa-eye",
    };
  },
  computed: {
    requiredValid() {
      return !(
        this.required &&
        (this.content == "" ||
          this.content == null ||
          this.content == "R$ " ||
          this.content == "R$ 0,00" ||
          this.converteMoedaFloat() <= 0)
      );
    },
    minValid() {
      return !(this.min && parseInt(this.content) < parseInt(this.min));
    },
    maxValid() {
      return !(this.max && parseInt(this.content) > parseInt(this.max));
    },
    lengthValid() {
      return !(this.length > 0 && String(this.content)?.length != this.length);
    },
    minLengthValid() {
      return !(this.minLength > 0 && this.content?.length < this.minLength);
    },
    maxLengthValid() {
      return !(this.maxLength > 0 && this.content?.length > this.maxLength);
    },
    state() {
      if (!this.validForm) return null;

      if (!this.stateCustom) return false;

      // if (this.content && this.required == true) return false;

      if (this.content && this.content?.length <= 0 && this.required == true)
        return false;

      if (this.minLength && this.content?.length >= this.minLength) return true;

      if (this.maxLength && this.content?.length > this.maxLength) return true;

      if (!this.requiredValid) return false;
      if (!this.minValid) return false;
      if (!this.maxValid) return false;
      if (!this.lengthValid) return false;
      if (!this.minLengthValid) return false;
      if (!this.maxLengthValid) return false;

      return true;
    },
    feedback() {
      if (!this.requiredValid) {
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label || "",
          })
        );
      }
      if (!this.minValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.VALOR_MINIMO").formatUnicorn({
            min: this.min,
          })
        );
      if (!this.maxValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.VALOR_MAXIMO").formatUnicorn({
            max: this.max,
          })
        );
      if (!this.lengthValid)
        return (
          this.lengthMessage ||
          this.$t("CAMPOS_VALIDACAO.TAMANHO").formatUnicorn({
            name: this.label,
            length: this.length,
          })
        );
      if (!this.minLengthValid)
        return (
          this.minLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPIMENTO_MINIMO").formatUnicorn({
            name: this.label,
            length: this.minLength,
          })
        );
      if (!this.maxLengthValid)
        return (
          this.maxLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPIMENTO_MAXIMO").formatUnicorn({
            name: this.label,
            length: this.maxLength,
          })
        );

      if (this.hasError) return this.errorMessage;
      return "";
    },
  },
  methods: {
    converteMoedaFloat() {
      let valor = `${this.value}`;
      if (valor.indexOf("R$") <= -1) {
        valor = "R$ " + valor;
      }

      if (!valor) return;
      let splitValor = valor.split("$");
      valor = splitValor[1];
      if (valor === "") {
        valor = 0;
      } else {
        valor = valor.replace(".", "");
        valor = valor.replace(",", ".");
        valor = parseFloat(valor);
      }

      return valor;
    },
    mascaraValor(valor) {
      valor = valor + "";
      valor = parseInt(valor.replace(/[\D]+/g, ""));
      valor = valor + "";
      valor = valor.replace(/([0-9]{2})$/g, ".$1");

      if (valor > 100) return "100.00";
      if (valor == "NaN") return "";
      if (valor.length == 3) return "0" + valor;

      return valor;
    },
    showError(message) {
      this.validForm = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    handleInput() {
      this.$emit("input", this.content);
      this.hasError = false;
    },
    valid() {
      this.validForm = true;
      return this.state;
    },
    clear() {
      this.content = "";
      this.validForm = false;
      this.$emit("input", this.content);
    },
    sendEnterEvent() {
      this.$emit("enter");
    },
  },
  watch: {
    value: {
      handler(value) {
        console.log(typeof value);
        this.content = this.mascaraValor(value);
      },
      deep: true,
      immediate: true,
    },
    content(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
  },
};
</script>
<style lang="scss" scoped>
input[readonly] {
  background-color: #f2f3f8 !important;
}
.button-eye {
  position: absolute;
  top: 31px;
  right: 31px;
  z-index: 9;

  .fas {
    font-size: 17px;
  }
}
</style>
