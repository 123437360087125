var year = new Date().getFullYear();

export default [
    { value: year.toString(), text: year.toString() },
    { value: (year + 1).toString(), text: (year + 1).toString() },
    { value: (year + 2).toString(), text: (year + 2).toString() },
    { value: (year + 3).toString(), text: (year + 3).toString() },
    { value: (year + 4).toString(), text: (year + 4).toString() },
    { value: (year + 5).toString(), text: (year + 5).toString() },
    { value: (year + 6).toString(), text: (year + 6).toString() },
    { value: (year + 7).toString(), text: (year + 7).toString() },
    { value: (year + 8).toString(), text: (year + 8).toString() },
    { value: (year + 9).toString(), text: (year + 9).toString() },
    { value: (year + 10).toString(), text: (year + 10).toString() },
    { value: (year + 11).toString(), text: (year + 11).toString() },
    { value: (year + 12).toString(), text: (year + 12).toString() },
    { value: (year + 13).toString(), text: (year + 13).toString() },
    { value: (year + 14).toString(), text: (year + 14).toString() },
    { value: (year + 15).toString(), text: (year + 15).toString() },
]