export default {
  DATA: "Data",
  CONTA: "Conta Bancária",
  NOME_ESTABELECIMENTO: "Estabelecimento",
  VALOR: "Valor",
  VALOR_ORIGINAL: "Valor original",
  DATA_PAGAMENTO: "Data de Pagamento",

  TOTAIS: {
    VALORES: "Total Valor",
    VALORES_ORIGINAIS: "Total Valor Original",
    DESAGIO: "Deságio"
  }
};
