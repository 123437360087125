import AutenticacaoService from "@/common/services/autenticacao/autenticacao.service";
import JwtService from "@/common/utils/jwt";

export const VERIFICAR_AUTENTICACAO = "verificarAunteticacao";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const LIMPAR_DADOS_AUTENTICACAO = "limparDadosAutenticacao";
export const SALVAR_DADOS_AUTENTICACAO = "salvarDadosAuntenticacao";
export const SETAR_USUARIO = "setUsuario";

const state = {
  erros: null,
  user: {},
  usuario : {},
  estaLogado: !!JwtService.buscarToken(),
};

const getters = {
  usuarioLogado(state) {
    return state.usuario;
  },
  estaLogado(state) {
    return state.estaLogado;
  },
  primeiroLogin(state) {
    return state.primeiroLogin;
  },
  ehAdmin(state){
    return state.usuario ? state.usuario.perfil == 'Valorem' : false
  }
};

const actions = {
  async [LOGIN](context, form) {
    return await AutenticacaoService.logar(form).then(async (result) => {
      context.commit(SALVAR_DADOS_AUTENTICACAO, result.data.data);
      return result.data.data;
    });
  },
  async [LOGOUT](context) {
    context.commit(LIMPAR_DADOS_AUTENTICACAO);
  },
  async [VERIFICAR_AUTENTICACAO](context) {
    if(localStorage.getItem('usuario'))
      context.commit(SETAR_USUARIO, JSON.parse(localStorage.getItem('usuario')));

    const _token = JwtService.buscarToken();
    if (_token === null || _token === undefined) {
      context.commit(LIMPAR_DADOS_AUTENTICACAO);
      return;
    }
    return await AutenticacaoService.validarToken(_token)
      .then(async (result) => {
        let claims = result.data.data.claims;
        localStorage.setItem("claims", JSON.stringify(claims));
      })
      .catch(() => {
        context.commit(LIMPAR_DADOS_AUTENTICACAO);
      });
  },
};

const mutations = {
  [SALVAR_DADOS_AUTENTICACAO](state, usuario) {
    state.estaLogado = true;
    state.usuario = {
      id : usuario.usuario.id,
      email : usuario.usuario.email,
      nomeUsuario : usuario.usuario.nomeUsuario,
      nome : usuario.usuario.nome,
      foto : usuario.usuario.foto,
      perfil : usuario.usuario.perfil.charAt(0).toUpperCase() +  usuario.usuario.perfil.slice(1)
    };
    state.errors = {};
    
    localStorage.setItem('usuario', JSON.stringify(state.usuario));

    if (usuario.accessToken) JwtService.salvarToken(usuario.accessToken);
    if (usuario.usuario.nomeUsuario) JwtService.salvarNome(usuario.usuario.nomeUsuario);
    if (usuario.usuario.email) JwtService.salvarEmail(usuario.usuario.email);
    if (usuario.usuario.id) JwtService.salvarId(usuario.usuario.id);
    if (usuario.precisaAlterarSenha) {
      state.primeiroLogin = usuario.precisaAlterarSenha;
      JwtService.salvarPrimeiroLogin(usuario.precisaAlterarSenha)
    }
  },
  [LIMPAR_DADOS_AUTENTICACAO](state) {
    const nextURL = window.location.origin + '/login';
    window.location.href = nextURL;
    window.location.assign(nextURL);
    window.location.replace(nextURL);
    state.estaLogado = false;
    state.usuario = {};
    state.errors = {};
    state.primeiroLogin = false;
    JwtService.excluirToken();
    JwtService.excluirNome();
    JwtService.excluirSobrenome();
    JwtService.excluirEmail();
    JwtService.excluirId();
    localStorage.removeItem('estabelecimentoSelecionado');
    localStorage.removeItem('usuario');
  },
  [SETAR_USUARIO](state, payload){
    localStorage.setItem('usuario', JSON.stringify(payload));
    state.usuario = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
