import Situacao from "./Situacao";
import Perfil from "./Perfil";
import TipoContato from "./TipoContato";
import TipoContaBancaria from "./TipoContaBancaria";
import Parcelas from "./Parcelas";
import Taxa from "./Taxa";
import Estados from "./Estados";
import Meses from "./Meses";
import QuantidadeCartoes from "./QuantidadeCartoes";
import Rav from "./Rav";
import TipoPagamento from "./TipoPagamento";
import NrCartoes from "./NrCartoes";
import Anos from "./Anos";
import Tef from "./Tef";
import StatusWebHook from "./StatusWebHook";
import Token from "./Token";
import Adquirente from "./Adquirente";
import AdquirenteImagem from "./AdquirenteImagem";
import AdquirenteCodigo from "./AdquirenteCodigo";
import AdquirenteFiltro from "./AdquirenteFiltro";
import Plataforma from "./Plataforma";
import Ligado from "./Ligado";

export default {
  Situacao,
  Perfil,
  TipoContato,
  TipoContaBancaria,
  Parcelas,
  Taxa,
  Estados,
  Meses,
  QuantidadeCartoes,
  Rav,
  TipoPagamento,
  NrCartoes,
  Anos,
  Tef,
  StatusWebHook,
  Token,
  Adquirente,
  AdquirenteImagem,
  AdquirenteCodigo,
  AdquirenteFiltro,
  Plataforma,
  Ligado,
  //Buscar enum pelo value
  getEnum(v) {
    let Enum = [
      ...Situacao,
      ...Perfil,
      ...TipoContato,
      ...TipoContaBancaria,
      ...Parcelas,
      ...Taxa,
      ...Estados,
      ...Meses,
      ...QuantidadeCartoes,
      ...Rav,
      ...TipoPagamento,
      ...NrCartoes,
      ...Anos,
      ...Tef,
      ...StatusWebHook,
      ...Token,
      ...Adquirente,
      ...AdquirenteImagem,
      ...AdquirenteCodigo,
      ...AdquirenteFiltro,
      ...Plataforma,
      ...Ligado
    ];
    if (v == -1) return { value: -1, text: "Total" };
    return Enum.find((el) => el.value == v);
  },
};
