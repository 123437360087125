<template>
  <b-form-group :label="label" :label-sr-only="noLabel" :label-class="{ 'text-required': required }">
    <b-input-group :state="state">
      <b-form-input 
        v-model="content"  
        :state="state" 
        @blur="handleBlur" 
        @input="handleInput" 
        type="date"
        :readonly="readonly" 
        :placeholder="placeholder" 
        :lazy="lazy"
        @wheel="$event.target.blur()" 
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
        }" 
        locale="br" 
        @keyup.enter="sendEnterEvent" 
        @keyup="sendCtrlVEvent" />
      <slot name="append"></slot>
    </b-input-group>
    <b-form-invalid-feedback :state="state" v-if="!hideFeedback">{{feedback}}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import helpers from "@/common/utils/helpers";
import moment from "moment";
export default {

  name: "InputDate",
  props: {
    stateCustom: { type: Boolean, default: true },
    value: { type: String, default: "" },
    type: { type: String, default: "date" },
    label: { type: String },
    size: { type: String },
    autocomplete: { type: String, default: "" },
    upper: { type: Boolean },
    noLabel: { type: Boolean, default: false },
    removeSpecialChars: { type: Boolean },
    removeWhiteSpaces: { type: Boolean },
    required: { type: Boolean },
    requiredMessage: { type: String },
    readonly: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    lengthMessage: { type: String },
    minLength: { type: Number, default: null },
    generalRef: { type: String, default: null },
    minLengthMessage: { type: String },
    maxLength: { type: Number, default: null },
    maxLengthMessage: { type: String },
    placeholder: { type: String },
    invalidEmailMessage: { type: String },
    validate: { type: Boolean, default: null },
    showPassword: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
    min: { type: [Number, String], required: false },
    max: { type: [Number, String], required: false },
    hideFeedback: { type: Boolean, default: false },
    propsError: { type: Boolean, default: null },
    validateOnChange: { type: Function, default: () => true }
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      hasError: false,
      errorMessage: "",
      inputPasswordIcon: "fas fa-eye",
      emitirBlurAlterado : false
    };
  },
  computed: {
    format() {
      if (this.type == "date") return "DD/MM/YYYY";
      if (this.type == "datetime") return "DD/MM/YYYY HH:mm";
      if (this.type == "time") return "HH:mm";
      return "DD/MM/YYYY";
    },
    formatEn() {
      if (this.type == "date") return "YYYY-MM-DD";
      if (this.type == "datetime") return "YYYY-MM-DD HH:mm";
      if (this.type == "time") return "HH:mm";
      return "YYYY-MM-DD";
    },
    requiredValid() {
      return !(this.required && (this.content == "" || this.content == null));
    },
    minValid() {
      return !(this.min && parseInt(this.content) < parseInt(this.min));
    },
    maxValid() {
      return !(this.max && parseInt(this.content) > parseInt(this.max));
    },
    lengthValid() {
      return !(this.length > 0 && String(this.content)?.length != this.length);
    },
    minLengthValid() {
      return !(this.minLength > 0 && this.content?.length < this.minLength);
    },
    maxLengthValid() {
      return !(this.maxLength > 0 && this.content?.length > this.maxLength);
    },
    validEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.content);
    },
    state() {
      if (!this.validateOnChange(this.content))
        return false

      if (!this.stateCustom) return false;

      if (this.minLength && this.content?.length >= this.minLength) return true;

      if (this.propsError == false) return false;

      if (!this.validForm) return null;

      if (!this.requiredValid) return false;
      if (!this.minValid) return false;
      if (!this.maxValid) return false;
      if (!this.lengthValid) return false;
      if (!this.minLengthValid) return false;
      if (!this.maxLengthValid) return false;
      if (this.type == "email") return this.validEmail;
      if (this.hasError) return false;
      if (this.validate != null) return this.validate;
      return true;
    },
    feedback() {
      if (!this.validateOnChange(this.content))
        return (
          this.dataMenorMessage ||
          this.$t("CAMPOS_VALIDACAO.DATA_MENOR")
        )
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (!this.minValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.VALOR_MINIMO").formatUnicorn({
            min: this.min,
          })
        );
      if (!this.maxValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.VALOR_MAXIMO").formatUnicorn({
            max: this.max,
          })
        );
      if (!this.lengthValid)
        return (
          this.lengthMessage ||
          this.$t("CAMPOS_VALIDACAO.TAMANHO").formatUnicorn({
            name: this.label,
            length: this.length,
          })
        );
      if (!this.minLengthValid)
        return (
          this.minLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPIMENTO_MINIMO").formatUnicorn({
            name: this.label,
            length: this.minLength,
          })
        );
      if (!this.maxLengthValid)
        return (
          this.maxLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPIMENTO_MAXIMO").formatUnicorn({
            name: this.label,
            length: this.maxLength,
          })
        );
      if (
        (this.type == "email" && !this.validEmail) ||
        (this.validate != null && !this.validate)
      )
        return (
          this.invalidEmailMessage ||
          this.$t("CAMPOS_VALIDACAO.INVALIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (this.hasError) return this.errorMessage;
      return "";
    },
  },
  methods: {
    showError(message) {
      this.validForm = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    formatarData() {
      return helpers.formatarData(this.content);
    },
    formatter(e) {
      if (e) {
        if (this.upper) e = e.toUpperCase();

        if (this.maxLength > 0 && e.length > this.maxLength) {
          e = e.substring(0, this.maxLength);
        }

        if (this.removeSpecialChars)
          e = e.replace(/[^a-z0-9\s]/gi, "").replace(/[_\s]/g, "-");

        if (this.removeWhiteSpaces) e = e.replace(/\s/g, "");
      }
      return e;
    },
    handleInput() {
      this.$emit("input", this.content);
      this.hasError = false;
      this.emitirBlurAlterado = this.content != ""
    },
    handleBlur(){
      if(this.emitirBlurAlterado)
        this.$emit("blurAlterado");
      this.$emit("blur")
    },  
    valid() {
      this.validForm = true;
      return this.state;
    },
    clear() {
      this.content = "";
      this.validForm = false;
      this.$emit("input", this.content);
    },
    sendEnterEvent() {
      this.$emit("enter");
    },
    sendCtrlVEvent(event) {
      if (event.keyCode == 86 && event.ctrlKey) {
        navigator.clipboard.readText().then(text => {
          text = text.replace(/(\r\n\t|\n|\r|\t)/gm, "");
          const [day, month, year] = text.split('/');
          let date = `${year}-${month}-${day}`;
          this.$emit("input", date);
          this.$emit("change", date);

        })
          .catch(err => {
            console.error('Failed to read clipboard contents: ', err);
          });
        this.$emit("enter");
      }
    },
  },
  watch: {
    value: {
      handler(value) {
        if(value){
          if(value.indexOf('/') > -1){
            this.content = moment(value, this.format).format(this.formatEn);
          }else{
            this.content = moment(value, this.formatEn).format(this.formatEn);
          }
        }else{
          this.content = null;
        }
      },
      deep: true,
      immediate: true,
    },
    content(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
  },
};
</script>
<style lang="scss" scoped>
input[readonly] {
  background-color: #f2f3f8 !important;
}

.button-eye {
  position: absolute;
  top: 31px;
  right: 31px;
  z-index: 9;

  .fas {
    font-size: 17px;
  }
}
</style>

