<template>
  <b-modal
    content-class="valorem-modal"
    :id="id"
    cancel-variant="outline-valorem-ciano"
    ok-variant="valorem-ciano"
    :cancel-title="tituloCancelar || $t('GERAL.CANCELAR')"
    :ok-title="tituloOk || $t('GERAL.SALVAR')"
    :ok-disabled="okDisabilitado"
    :size="tamanho"
    @ok="emitirOk"
    @cancel="emitirCancelar"
    @hidden="verificarFecharModal"
    :hide-footer="esconderRodape"
    :hide-header="esconderCabecalho"
    :no-close-on-backdrop="bloquearFecharBackdrop"
    :no-close-on-esc="bloquearFecharEsc"
  >
    <template #modal-title>
      <slot name="modal-title">{{ tituloModal }}</slot>
    </template>

    <slot></slot>
  </b-modal>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: () => {
        return `modal-${Math.random()}`;
      },
    },
    tituloCancelar: {
      type: String,
      default: null,
    },
    tituloOk: {
      type: String,
      default: null,
    },
    tituloModal: {
      type: String,
      default: "Modal",
    },
    okDisabilitado: {
      type: Boolean,
      default: false,
    },
    esconderRodape: {
      type: Boolean,
      default: false,
    },
    esconderCabecalho: {
      type: Boolean,
      default: false,
    },
    bloquearFecharBackdrop: {
      type: Boolean,
      default: false,
    },
    bloquearFecharEsc: {
      type: Boolean,
      default: false,
    },
    tamanho: {
      type: String,
      default: "full",
    },
  },
  methods: {
    abrirModal: function () {
      this.$bvModal.show(this.id);
    },
    fecharModal: function () {
      this.$nextTick(() => {
        this.$bvModal.hide(this.id);
      });
    },
    verificarFecharModal(bvModalEvent) {
      bvModalEvent.preventDefault();
    },
    emitirOk: function (e) {
      e.preventDefault();

      this.$emit("ok");
    },
    emitirCancelar: function () {
      this.$emit("cancelar");
    },
  },
};
</script>
<style lang=""></style>
