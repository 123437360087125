import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
  {
    name: "transacoes",
    path: "/transacoes",
    meta: {
      middleware: verificaAutenticacao,
    },
    beforeEnter: (to, from, next) => {
      verificarPermissao(next, "Usuario");
    },
    component: () => import("@/views/transacoes/Index"),
  },
];
