<template>
  <div>
    <b-form-group :label="label">
      <b-form-file
        ref="file"
        v-model="file"
        :state="state"
        @input="handleInput"
        :placeholder="placeholderT"
        :drop-placeholder="dropPlaceholderT"
        :browse-text="browseTextT"
        :accept="accept"
        :disabled="disabled"
        v-if="!Boolean(file)"
      />
      <b-form-invalid-feedback>{{ requiredMessageT }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="Boolean(file) && isImage && showImage"
      class="mb-1 text-center"
    >
      <img :src="srcPreview" class="preview" />
    </b-form-group>
    <div v-if="Boolean(file)" class="mt-1 text-center">
      <b-button
        variant="danger"
        class="btn-table"
        size="sm"
        v-if="!disabled"
        @click="excluir()"
      >
        {{ $t("GERAL.REMOVER") }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputFile",
  props: {
    value: { type: File },
    label: { type: String, required: true },
    required: { type: Boolean },
    requiredMessage: { type: String },
    placeholder: { type: String },
    dropPlaceholder: { type: String },
    browseText: { type: String },
    showImage: { type: Boolean, default: true },
    onlyImage: { type: Boolean },
    accept: { type: String },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      file: this.value,
      srcPreview: "",
      textXml: "",
      validarArquivo: false,
    };
  },
  computed: {
    isImage() {
      return this.file?.type?.indexOf("image") != -1;
    },
    isXml() {
      return this.file?.type?.indexOf("xml") != -1;
    },
    requiredMessageT() {
      return (
        this.requiredMessage ||
        this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
          name: this.label,
        })
      );
    },
    placeholderT() {
      return (
        this.placeholder ||
        this.$t("GERAL.ARQUIVO").formatUnicorn({ name: this.label })
      );
    },
    dropPlaceholderT() {
      return (
        this.dropPlaceholder ||
        this.$t("GERAL.SOLTE_ARQUIVO_AQUI").formatUnicorn({
          name: this.label,
        })
      );
    },
    browseTextT() {
      return (
        this.browseText ||
        this.$t("GERAL.SELECIONAR").formatUnicorn({
          name: this.label,
        })
      );
    },
    requiredValid() {
      return !(this.required && this.file == null);
    },
    state() {
      if (!this.validarArquivo) return null;

      if (!this.requiredValid) return false;

      return true;
    },
  },
  methods: {
    excluir() {
      this.file = null;
    },
    getFile() {
      return this.srcPreview?.replace(/^data:.+;base64,/, "");
    },
    getBase64() {
      return this.srcPreview;
    },
    handleInput() {
      this.$emit("input", this.file);
      this.srcPreview = null;
      this.readFileAsDataURL();
      this.readFileAsXml();
    },
    readFileAsDataURL() {
      if (this.file) {
        let readerUrl = new FileReader();
        readerUrl.onload = (event) => {
          this.srcPreview = event.target.result;

          if (this.onlyImage && !this.isImage) {
            this.file = null;
            this.srcPreview = null;
          }
        };
        readerUrl.readAsDataURL(this.file);
      }
    },
    readFileAsXml() {
      if (this.file && this.isXml) {
        let readerXml = new FileReader();
        readerXml.onload = (event) => {
          if (this.isXml) {
            this.textXml = event.target.result;
            this.$emit("input", this.file);
          }
        };
        readerXml.readAsText(this.file);
      }
    },
    setFile(file) {
      this.file = file;
      this.handleInput();
    },
    valid() {
      this.validarArquivo = true;
      return this.state;
    },
    clear() {
      this.content = "";
      this.validarArquivo = false;
      this.$emit("input", this.file);
    },
  },
  watch: {
    value: {
      handler(value) {
        if (this.file != value) {
          this.file = value;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.preview {
  max-width: 300px;
  max-height: 300px;
  margin: auto;
}
</style>
