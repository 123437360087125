export default {
  ID: "Id",
  DATA: "Data",
  EMPRESA: "Empresa",
  STATUS: "Status",
  NIT: "NIT",
  CNPJ_ESTABELECIMENTO: "CNPJ Estabelecimento",
  NOME_ESTABELECIMENTO: "Estabelecimento",
  HORA: "Hora",
  NSU_HOST: "NSU Host",
  BANDEIRA: "Bandeira",
  VALOR: "Valor",
  VALOR_ORIGINAL: "Valor original",
  ESTADO_TRANSACAO: "Estado da Transação",
  CODIGO_AUTORIZACAO: "Cod. Autorização",
  NUMERO_PARCELAS: "Nº de Parcelas",
  DATA_LANCAMENTO: "Data de Lançamento",
  DATA_PAGAMENTO: "Data de Pagamento",
  DESTACAR_DATA: "Verificar",
  NUMERO_CARTAO: "Número Cartão",
  TIPO_TRANSACAO: "Tipo Transação",
  MENSAGEM_PAGAMENTO: "Mensagem de pagamento",
  STATUS_REQUISICAO_PAGAMENTO: "Status Pagamento",
  STATUS_PAGAMENTO: "Status Pagamento",
  TIPO_PAGAMENTO: "Tipo",
  REALIZAR_ANTECIPACAO: "Realizar antecipação?",
  QUANTIDADE_CARTOES: "Qtd. Cartões",
  DATA_LIQUIDACAO: "Data da Liquidação",
  STATUSWEBHOOK: "Status Webhook",
  ADQUIRENTE: "Adquirente",
  ID_EXTERNO: "Identifição Externa",
  PLATAFORMA: "Plataforma"
};
