export default {
  NUMERO_CARTAO: "Número do Cartão",
  NOME_CARTAO: "Nome no Cartão",
  CPF_CNPJ: "CPF",
  DATA_EXPIRACAO: "Data de Expiração",
  MES_VENCIMENTO: "Mês Venc.",
  ANO_VENCIMENTO: "Ano Venc.",
  CODIGO_SEGURANCA: "Cód. Seg. (CVV)",
  ENDERECO: "Endereço",
  LOGRADOURO: "Logradouro",
  NUMERO: "Número",
  COMPLEMENTO: "Complemento",
  BAIRRO: "Bairro",
  CIDADE: "Cidade",
  ESTADO: "Estado",
  UF: "UF",
  CEP: "CEP",
  VALOR: "Valor",
  VALOR_PARCIAL: "Valor parcela",
  VALOR_TOTAL: "Valor Total",
  TOTAL_PARCELAS: "Total de parcelas",
  MENSAGEM_SUCESSO: "Operação efetuada com sucesso",
  QUANTIDADE_CARTOES: "Selecione a quantidade de Cartões",
  TELEFONE: "Telefone",
  COMPRADOR: "Informações do comprador",
  COBRANCA: "Endereço de cobrança",
  LINK_EXPIRADO: "O link está expirado, ele tem duração de 30 minutos.",
  LINK_EXPIRADO2: "Favor gerar uma nova guia."
};
