import Swal from "sweetalert2";
const confirmarMixin = Swal.mixin({
  customClass: {
    container: "swal-customizado",
    confirmButton: "btn btn-valorem-ciano",
    cancelButton: "btn btn-outline-valorem-ciano",
    denyButton: "btn btn-outline-valorem-ciano mr-2",
    closeButton: "btn",
  },
  buttonsStyling: false,
});
const alertasMixin = Swal.mixin({
  customClass: {
    container: "swal-customizado swal-alertas",
    confirmButton: "btn btn-valorem-ciano",
    cancelButton: "btn btn-outline-valorem-ciano",
    denyButton: "btn btn-outline-valorem-ciano mr-2",
    closeButton: "btn",
    icon: "icon-class",
  },
  buttonsStyling: false,
});
export const AlertasMixin = {
  methods: {
    confirmar: function (
      titulo,
      mensagem,
      botaoConfirmar = "Sim",
      botaoCancelar = "Não"
    ) {
      return new Promise((resolve) => {
        confirmarMixin
          .fire({
            title: titulo,
            text: mensagem,
            showDenyButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: botaoConfirmar,
            denyButtonText: botaoCancelar,
          })
          .then((result) => {
            resolve(result.isConfirmed);
          });
      });
    },
    alertaSucesso: function (mensagem, permitirFechar = true) {
      return new Promise((resolve) => {
        alertasMixin
          .fire({
            text: mensagem,
            icon: "success",
            showConfirmButton: permitirFechar,
            allowOutsideClick: permitirFechar,
            allowEscapeKey: permitirFechar,
          })
          .then(() => {
            resolve();
          });
      });
    },
    alertaErro: function (mensagem) {
      alertasMixin.fire({
        text: mensagem,
        icon: "error",
        confirmButtonText: "Ok",
      });
    },
    alertaAviso: function (mensagem) {
      alertasMixin.fire({
        text: mensagem,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    },
    toast: function (mensagem, options) {
      this.$bvToast.toast(mensagem, {
        title: options.title ? options.title : "",
        toaster: options.position ? options.position : "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: options.variant ? options.variant : "info",
      });
    },

    toastSucesso: function (mensagem) {
      this.$toast.success(mensagem, {
        toastClassName: "toast-sucesso",
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },

    toastErro: function (mensagem) {
      this.$toast.error(mensagem, {
        toastClassName: "toast-erro",
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },

    toastAlerta: function (mensagem) {
      this.$toast.warning(mensagem, {
        toastClassName: "toast-alerta",
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
