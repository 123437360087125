export default {
    TITULO : "Dashboard",
    LIMITE_ANTECIPACAO : "Limite antecipação",
    ASSINATURAS_ANTECIPADAS : "Assinaturas antecipadas",
    LIMITE_DISPONIVEL : "Limite disponível para antecipação",
    ASSINATURAS_ATRASADAS : "Assinaturas antecipadas em atraso",
    LINK_AVISTA : "Total links à Vista/Parcelado",
    LINK_RECORRENCIA : "Total links recorrência",
    TICKET_MEDIO : "Ticket médio",
    TAXA_CONVERSAO : "Taxa de conversão",
    FILTRO : "Estabelecimento",
    PERIODO : "Periodo",
    GERENCIAMENTO : "Gerenciamento",
    MENU : {
        CLIENTES : {
            DETALHE : "Gerenciamento dos clientes"
        },
        ESTABELECIMENTOS : {
            DETALHE : "Gerenciamento dos estabelecimento"
        },
        PLANOS : {
            DETALHE : "Gerenciamento dos planos de assinaturas"
        },
        PRODUTOS : {
            DETALHE : "Gerenciamento dos produtos"
        },
        USUARIOS : {
            DETALHE : "Gerenciamento dos usuários"
        },
        GRUPO_ECONOMICO : {
            DETALHE : "Gerenciamento dos grupos econômico"
        },
    }
}