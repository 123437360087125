import helpers from "../utils/helpers";

export default async function verificarPermissao(next, permissao) {
  if (!helpers.validarAcesso(permissao).visualizar) {
    next({ name: "transacoes" });
    return;
  }

  return next();
}
